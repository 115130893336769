<template>
    <div id="portal" v-if="showPortal">
        <div style="position: fixed; top: 0; left: 0; right: 0; height: 60px; z-index: 100; border-bottom: 1px solid #ddd" class="portal-view">
            <Menu mode="horizontal" theme="primary" style="height: 59px">
                <img class="layout-logo" :src="currentSystem.sysConfigMap ? currentSystem.sysConfigMap.sysLogo : ''" />
                <div v-if="showMenuScroll" class="mscroll" style="width: 20px" title="上一页" @click="scrollMenuList(-1)">
                    <Icon type="ios-arrow-back" :color="currentSystem.sysConfigMap.headerFontcolor" size="16" />
                </div>
                <div class="layout-nav" :style="'width:' + layoutnavWidth">
                    <template v-for="(menu, index) in menuList">
                        <Submenu :key="menu.id" :name="menu.id" :class="'S' + menu.id" style="padding: 0 8px" v-if="menu.subMenus && menu.subMenus.length > 0">
                            <div @click="goSubmenu(index)" style="display: flex; flex-wrap: nowrap" slot="title">
                                <img :src="menu.icon == undefined ? '-' : menu.icon" style="margin: 22px 5px 0 0; width: 16px; height: 16px" onerror="this.style.display='none';" />
                                <div style="white-space: nowrap">{{ menu.name }}</div>
                            </div>

                            <template v-for="submenu in menu.subMenus">
                                <MenuGroup :key="submenu.id" :title="submenu.name" v-if="submenu.subMenus && submenu.subMenus.length > 0">
                                    <MenuItem v-for="smenu in submenu.subMenus" :key="smenu.id" :name="smenu.id" :class="'S' + smenu.id">
                                        <div @click="goSubmenu(index, smenu.url, smenu.id, submenu)" style="display: flex; height: 100%; padding: 12px">
                                            <img :src="smenu.icon == undefined ? '-' : smenu.icon" style="margin: 3px 5px 0 0; width: 16px; height: 16px" onerror="this.src='/images/nopic.png';" />
                                            <div>{{ smenu.name }}</div>
                                            <img src="/images/nopic.png" style="margin: 3px 5px 0 0; width: 10px; height: 16px" />
                                        </div>
                                    </MenuItem>
                                </MenuGroup>
                                <MenuItem :key="submenu.id" :name="submenu.id" :class="'S' + submenu.id" v-else>
                                    <div @click="goSubmenu(index, submenu.url, submenu.id, submenu)" style="display: flex; height: 100%; padding: 12px">
                                        <img :src="submenu.icon == undefined ? '-' : submenu.icon" style="margin: 3px 5px 0 0; width: 16px; height: 16px" onerror="this.src='/images/nopic.png';" />
                                        <div>{{ submenu.name }}</div>
                                        <img src="/images/nopic.png" style="margin: 3px 5px 0 0; width: 10px; height: 16px" />
                                    </div>
                                </MenuItem>
                            </template>
                        </Submenu>
                        <MenuItem :key="menu.id" :name="menu.id" style="padding: 0 12px" :class="'S' + menu.id" v-else>
                            <div @click="goSubmenu(index, menu.url, menu.id, menu)" style="display: flex; flex-wrap: nowrap">
                                <img :src="menu.icon == undefined ? '-' : menu.icon" style="margin: 22px 5px 0 0; width: 16px; height: 16px" onerror="this.style.display='none';" />
                                <div style="white-space: nowrap">{{ menu.name }}</div>
                            </div>
                        </MenuItem>
                    </template>
                </div>
                <div v-if="showMenuScroll" class="mscroll" style="width: 20px" title="下一页" @click="scrollMenuList(1)">
                    <Icon type="ios-arrow-forward" :color="currentSystem.sysConfigMap.headerFontcolor" size="16" />
                </div>
                <div class="layout-right">
                    <Dropdown>
                        <a href="javascript:void(0)" :style="'color:' + (currentSystem.sysConfigMap ? currentSystem.sysConfigMap.headerFontcolor : '') + ';margin:0 10px 0 5px;'">
                            <Avatar :src="loginInfo.userinfo.avatarUrl" />
                            {{ loginInfo.userinfo.nickName }}
                            <Icon type="md-more" size="20" />
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem>
                                <div @click="showMDPsw">
                                    <Icon type="md-contact" />
                                    修改密码
                                </div>
                            </DropdownItem>
                            <DropdownItem v-if="sysList && sysList.length > 1">
                                <div @click="showOEMlistForm">
                                    <Icon type="md-apps" />
                                    返回门户列表
                                </div>
                            </DropdownItem>
                            <DropdownItem>
                                <div @click="logout">
                                    <Icon type="ios-exit" />
                                    安全退出门户
                                </div>
                            </DropdownItem>
                            <DropdownItem v-if="buttonRoot && buttonRoot == '1003'">
                                <div @click="changeOrg">
                                    <Icon type="md-apps" />
                                    切换机构
                                </div>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </Menu>
        </div>

        <div id="leftmenuarea" style="position: fixed; top: 60px; left: 0; width: 200px; bottom: 0; overflow: auto; border-right: 1px solid #ddd">
            <Menu theme="dark" accordion style="width: auto; min-height: 100%; padding-top: 10px">
                <template v-for="menu in subMenus">
                    <Submenu :key="menu.id" :name="menu.id" v-if="menu.subMenus && menu.subMenus.length > 0">
                        <template slot="title">
                            <div @click="syncTopMenu(menu.url, menu.id, menu)" style="display: flex; height: 100%; padding: 13px">
                                <img :src="menu.icon == undefined ? '-' : menu.icon" style="margin: 3px 5px 0 0; width: 16px; height: 16px" onerror="this.src='/images/nopic.png';" />
                                <div>{{ menu.name }}</div>
                            </div>
                        </template>
                        <template v-for="submenu in menu.subMenus">
                            <MenuGroup :key="submenu.id" :title="submenu.name" v-if="submenu.subMenus && submenu.subMenus.length > 0">
                                <MenuItem v-for="smenu in submenu.subMenus" :key="smenu.id" :name="smenu.id" :class="'S' + smenu.id">
                                    <div @click="syncTopMenu(smenu.url, smenu.id, smenu)" style="display: flex; height: 100%; padding: 13px">
                                        <img :src="smenu.icon == undefined ? '-' : smenu.icon" style="margin: 3px 5px 0 0; width: 16px; height: 16px" onerror="this.src='/images/nopic.png';" />
                                        <div>{{ smenu.name }}</div>
                                    </div>
                                </MenuItem>
                            </MenuGroup>
                            <MenuItem :key="submenu.id" :name="submenu.id" :class="'S' + submenu.id" v-else>
                                <div @click="syncTopMenu(submenu.url, submenu.id, submenu)" style="display: flex; height: 100%; padding: 13px">
                                    <img :src="submenu.icon == undefined ? '-' : submenu.icon" style="margin: 3px 5px 0 0; width: 16px; height: 16px" onerror="this.src='/images/nopic.png';" />
                                    <div>{{ submenu.name }}</div>
                                </div>
                            </MenuItem>
                        </template>
                    </Submenu>

                    <MenuItem :class="'S' + menu.id" :key="menu.id" :name="menu.id" v-else>
                        <div @click="syncTopMenu(menu.url, menu.id, menu)" style="display: flex; height: 100%; padding: 13px">
                            <img :src="menu.icon == undefined ? '-' : menu.icon" style="margin: 3px 5px 0 0; width: 16px; height: 16px" onerror="this.src='/images/nopic.png';" />
                            <div>{{ menu.name }}</div>
                        </div>
                    </MenuItem>
                </template>
            </Menu>
        </div>

        <div class="leftmenubtn" @click="showleftMenu" :title="menustate.title">
            <Icon style="margin-left: -4px" :type="menustate.class" />
        </div>

        <div id="contentarea" style="position: fixed; top: 60px; left: 200px; right: 0; bottom: 0">
            <iframe id="mainFrame" name="mainFrame" ref="mainFrame" style="width: 100%; height: 100%; border: 0px" />
            <iframe id="loginfrm" style="width: 0%; heyight: 0%; border: 0px; display: none" />
        </div>

        <div class="login_layout" style="display: ''">
            <img src="/images/loginlogo.png" style="height: 100px; position: absolute; top: calc((100vh - 550px) / 2 - 105px)" />
            <div id="bgimage" class="login_image" :style="'background-image:url(' + oemInfo.bgimages[0] + ');'"></div>

            <!-- 客服 -->
            <div class="service-box" ref="service_box">
                <div class="we-box">
                    <div class="title">请用微信手机端扫码咨询客服</div>
                    <div class="qrcode">
                        <img class="img" src="/images/we_qrcode.jpg" />
                    </div>
                    <div class="phone">客服电话：18148789092</div>
                </div>
                <div class="apply-box">
                    <div class="flow-box">
                        <div class="title">管理员申请通道</div>
                        <div class="name">第一步下载申请表填写并盖章</div>
                        <p class="link" @click="download('https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20230811/847933196.doc', '随约系统管理员授权备案表.doc')">
                            随约系统管理员授权备案表-下载
                        </p>
                        <p class="link" @click="download('https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20230811/944352499.doc', '机构-随约系统管理员授权备案表.doc')">
                            机构-随约系统管理员授权备案表-下载
                        </p>
                        <div class="name">第二步扫码填写并拍照上传申请表图片</div>
                    </div>
                    <div class="qrcode-box">
                        <div class="title">随约系统管理员授权备案表</div>
                        <div class="qrcode">
                            <img class="img" src="/images/apply_qrcode.jpg" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="login_div bounceIn animated" id="loginWin" style="z-index: 300">
                <img id="clientloginlogo" style="width: 100%" src="/images/loginbg.jpg" />
                <Tooltip :content="logintype.cur.msg" placement="left-end" style="position: absolute; top: 0px; right: 0px">
                    <img :src="logintype.cur.url" style="width: 100px; cursor: pointer" @click="loginType" />
                </Tooltip>
                <div style="width: 350px; margin: 0 55px; margin-top: 50px">
                    <div id="userlogin">
                        <Form ref="formLogin" :model="formLogin" :rules="ruleLogin">
                            <template v-if="logintype.cur.type == 'qrcode'">
                                <FormItem prop="mobile">
                                    <Input placeholder="手机号码" style="width: 350px" size="large" v-model="formLogin.mobile" @on-enter="login">
                                        <Icon type="ios-contact" slot="prefix" />
                                    </Input>
                                </FormItem>
                                <FormItem prop="password">
                                    <Input
                                        type="password"
                                        password
                                        placeholder="密码（6~16位，区分大小写）"
                                        style="width: 350px; margin-top: 10px"
                                        size="large"
                                        v-model="formLogin.password"
                                        @on-enter="login"
                                    >
                                        <Icon type="ios-unlock" slot="prefix" />
                                    </Input>
                                </FormItem>
                                <div style="display: flex; align-items: center; justify-content: flex-start; margin-top: 35px">
                                    <FormItem prop="captcha">
                                        <Input placeholder="验证码" style="width: 200px" size="large" v-model="formLogin.captcha" @on-enter="login">
                                            <Icon type="logo-angular" slot="prefix" />
                                        </Input>
                                    </FormItem>
                                    <img style="width: 120px; height: 40px; margin: -25px 0 0 20px; cursor: pointer" title="点击刷新" @click="randCode" :src="randcodeAction" />
                                </div>
                                <Button type="success" :loading="loginloading" @click="login" style="width: 350px; margin-top: 10px" size="large">
                                    <span v-if="!loginloading">登 录</span>
                                    <span v-else>验证中...</span>
                                </Button>
                                <div style="text-align: center; margin-top: 40px; text-decoration: underline; color: #2db7f5; cursor: pointer; user-select: none" @click="modalStatusFn(true)">
                                    密码重置
                                </div>
                            </template>
                            <template v-else>
                                <div style="margin: -30px 0; text-align: center">
                                    <img :src="captchaAppletQr.img ? 'data:image/gif;base64,' + captchaAppletQr.img : ''" style="height: 299px; min-width: 260px; background-color: #ddd" />
                                    <div style="width: 100%; font-size: 16px; text-align: center">请使用微信扫码登录</div>
                                </div>
                            </template>
                        </Form>
                    </div>
                </div>
                <div
                    style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        height: 137px;
                        margin-top: 50px;
                        line-height: 1.8em;
                        color: #555;
                        font-size: 16px;
                        background-color: #f9f9f9;
                    "
                    v-html="oemInfo.copyright != null ? oemInfo.copyright.replace('|', '<br>') : ''"
                ></div>
                <!-- </center> -->
            </div>
        </div>

        <div class="login_div" id="syslistWin" style="display: none">
            <div style="width: 100%; height: 60px; background: #515a6e; border-radius: 5px 5px 0 0">
                <div style="font-size: 16px; color: #fff; line-height: 60px; float: left; margin-left: 10px">
                    <Avatar :src="loginInfo.userinfo.avatarUrl" style="margin-top: -5px" />
                    {{ loginInfo.userinfo.nickName }} [{{ loginInfo.userinfo.mobile }}] 已登录，平台为您提供以下应用：
                </div>
                <div style="text-align: right; line-height: 60px; float: right; margin-right: 10px">
                    <Icon type="ios-close" title="退出登录" color="#fff" size="50" style="margin-top: 5px; cursor: pointer" @click="logout" />
                </div>
            </div>
            <div id="systemlist" style="width: 96%; margin: 0 2%; margin-top: 20px; overflow-x: hidden; overflow-y: auto" v-if="loginInfo.login">
                <div class="sysitem fadeInLeftBig animated" v-for="(list, index1) in sysList" :key="'syslist' + index1" @click="clicksystem(index1)">
                    <img :src="list.sysConfigMap.sysImage" />
                    <p>{{ list.systemName }}</p>
                </div>
            </div>
        </div>

        <!--修改/找回密码-->
        <LiefengModal title="修改/密码重置" width="400px" :fullscreen="false" :value="modalStatus" @input="modalStatusFn">
            <template v-slot:contentarea>
                <Form ref="editLogin" :model="editLogin" :rules="ruleLogin">
                    <FormItem prop="mobile">
                        <Input placeholder="手机号码" maxlength="11" style="width: 350px" size="large" v-model="editLogin.mobile">
                            <Icon type="ios-contact" slot="prefix" />
                        </Input>
                    </FormItem>
                    <FormItem prop="password">
                        <Input
                            type="password"
                            password
                            placeholder="请设置新密码"
                            style="width: 350px; margin-top: 10px"
                            size="large"
                            v-model="editLogin.password"
                            maxlength="64"
                            @on-change="changePassword"
                        >
                            <Icon type="ios-unlock" slot="prefix" />
                        </Input>
                        <!-- <div style="padding-left: 5px; color: #ed4014" v-if="showPasswordTip">
                            <p>密码长度为8-64位；</p>
                            <p>需同时包含大写或小写字母、数字、特殊字符中的三种。</p>
                        </div> -->
                    </FormItem>
                    <div style="display: flex; align-items: center; justify-content: flex-start">
                        <FormItem prop="captcha" class="capcth-div">
                            <Input placeholder="验证码" style="width: 200px" size="large" v-model="editLogin.captcha" maxlength="6">
                                <Icon type="logo-angular" slot="prefix" />
                            </Input>
                            <div v-if="showBtn" class="captcha-btn" @click="getCaptcha">{{ captchaTitle }}</div>
                            <div v-else class="captcha-btn none">{{ captchaTitle + "秒之后重新获取" }}</div>
                        </FormItem>
                    </div>
                </Form>
                <div :style="`padding-left: 5px; color:${showPasswordTip ? '#ed4014' : '#837d7d'} `">
                    <p>密码规则：</p>
                    <p>密码长度为8-64位；</p>
                    <p>需同时包含大写或小写字母、数字、特殊字符中的三种。</p>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button @click="saveModal" :loading="editLoading" type="info">确定修改</Button>
            </template>
        </LiefengModal>

        <!--切换机构-->
        <LiefengModal title="切换机构" width="400px" height="500px" :fullscreen="false" :value="orgStatus" @input="orgStatusFn">
            <template v-slot:contentarea>
                <div>
                    <div style="display: flex; margin-bottom: 10px">
                        <Input style="width: 200px" v-model="keyword" placeholder="请输入关键字进行查询" />
                        <Button type="primary" style="margin-left: 10px" @click="searchBtn">查询</Button>
                        <Button type="info" @click="restBtn" style="margin-left: 10px">重置</Button>
                    </div>
                    <div class="org-content" style="display: flex">
                        <div class="left" style="width: 30%">
                            <Menu ref="menu" theme="light" :active-name="current" @on-select="selectMenu">
                                <MenuItem :name="item.id" v-for="item in orgList" :key="item.id">{{ item.name }}</MenuItem>
                            </Menu>
                        </div>
                        <div class="right" style="flex: 1">
                            <div :class="orgSelect == index ? 'org-item active' : 'org-item'" @click="orgSelect = index" v-for="(item, index) in rightList" :key="index">{{ item.orgName }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button @click="orgSave" type="info">确定切换</Button>
            </template>
        </LiefengModal>
    </div>

    <router-view v-else-if="!showPortal" />
</template>
<script>
import "../utils/animate.css"
import proxy from "@/api/proxy.js"
import LiefengModal from "@/components/LiefengModal"
import { validateMobile } from "@/utils/validate.js"

export default {
    components: { LiefengModal },
    data() {
        return {
            currentSystem: {},
            frameUrl: "",
            norightUrl: "/noRight", //无权限跳转页
            preUrl: "", //上一级的地址
            loginAgain: false, //重新登录标识，登录后直接跳转到preUrl
            loginloading: false,
            showMenuScroll: false,
            formLogin: {
                mobile: "",
                password: "",
                captcha: "",
            },
            ruleLogin: {
                mobile: [{ required: true, validator: validateMobile, trigger: "blur" }],
                password: [{ required: true, message: "密码不能为空。", trigger: "blur" }],
                captcha: [{ required: true, message: "验证码不能为空。", trigger: "blur" }],
            },
            isPortal: null, //是否门户框架
            showPortal: null, //是否显示框架
            menustate: { class: "ios-arrow-back", title: "点击隐藏侧边栏" }, //侧边栏控制
            oemInfo: {
                //   oemcode: "zjsm", //oem代码
                //  oemname: "随约平台", //OEM门户名称
                //  copyright: "广州市民政局主导建设", //版权信息
                // loginlogo: "/images/loginlogo.png", //登录页LOGO
                bgimages: [
                    "/images/bg.jpg",
                    // "/images/bg1.jpg",
                    // "/images/bg2.jpg",
                    // "/images/bg3.jpg",
                    // "/images/bg4.jpg",
                ], //登录背景图片列表
            },
            bg_i: -1,
            loginInfo: {
                login: false, //登录成功与否：true or false
                right: false, //功能访问权限：true or false
                msg: "", //返回消息，失败原因等
                userinfo: {
                    //登录用户信息
                    currentOemCode: "",
                    custGlobalId: "",
                    master: 0,
                    mobile: "",
                    nickName: "",
                    oemCodes: [],
                    openId: "",
                    orgCode: "",
                    orgName: "",
                    systemList: [],
                    token: "",
                    avatarUrl: "",
                },
            },
            userButtonRoot: [], //获取按钮权限
            sysList: [
                {
                    appCode: "", //子系统代码
                    appName: null,
                    domain: null,
                    gmtCreate: 0,
                    id: "",
                    internetIp: null,
                    intranetIp: null,
                    loginAccount: "",
                    password: "",
                    systemName: "", //子系统名称
                    tenantHostId: null,
                    tenantId: "",
                    tenantName: null,
                    sysConfigMap: {
                        //           sysHomePage: "/services", //子系统首页地址
                        //           sysLogo: "/images/portallogo_black.png", //子系统LOGO
                        //           sysImage: "/images/bg2.jpg", //子系统形象图片
                        //           isThirdSystem:false,//第三方系统标识
                        //           domain:"",//第三方系统的网址
                        //           headerBgcolor: "#2d8cf0", //门户表头背景颜色#2d8cf0
                        //           headerFontcolor: "#fff", //门户表头字体颜色#fff
                        //           headerSelectedBgcolor: "#ddefe7", //门户表头选中背景颜色#fff
                        //           headerSelectedFontcolor: "#333", //门户表头选中字体颜色#2d8cf0
                        //           leftMenuBgcolor: "#515a6e", //门户左侧菜单颜色#515a6e
                        //           leftMenuitemBgcolor: "#363e4f", //门户左侧菜单子项颜色#363e4f
                        //           leftMenuFontcolor: "rgba(255,255,255,.7)", //门户左侧菜单字体颜色 rgba(255,255,255,.7)
                        //           leftMenuSelectedBgcolor: "#2d8cf0", //门户左侧菜单选中颜色#2d8cf0
                        //           leftMenuSelecteFontcolor: "#fff", //门户左侧菜单选中字体颜色#fff
                    },
                },
            ],
            custGlobalId: "", //旧系统通过该ID直接读取用户信息。
            menuList: [],
            subMenus: [],
            layoutnavWidth: "calc(100% - 400px)",
            randcodeAction: "", //验证码IMG base64
            captchaId: "", //验证码captchaId
            captchaAppletQr: {},
            logintype: {
                cur: {},
                compute: {
                    type: "compute",
                    url: "/images/lg_compute.png",
                    msg: "切换至密码登录",
                },
                qrcode: {
                    type: "qrcode",
                    url: "/images/lg_qrcode.png",
                    msg: "切换至扫码登录",
                },
            },
            monitorQRTimeout: -1,

            // 是否跳转到上次进入的界面
            gotoStatus: false,

            // 修改找回密码弹窗
            modalStatus: false,
            // 修改找回密码
            editLogin: {
                mobile: "",
                password: "",
                captcha: "",
            },
            // 存储节流定时器
            timer: null,
            showPasswordTip: false,
            captchaTitle: "获取验证码",
            timerDown: null,
            showBtn: true,
            editLoading: false,
            appCode: "", // 存储本次点击的appCode

            menuDesc: "",

            // 切换机构
            buttonRoot: "",
            orgStatus: false,
            current: 0,
            orgList: [],
            rightList: [],
            orgSelect: null,

            keyword: "",
        }
    },
    created() {
        //渲染html前

        //跨域处理：
        //if( window.location.href.indexOf("liefengtech.com")>-1) document.domain="liefengtech.com";

        var that = this

        if (this.checkPortal()) {
            //是否主页面

            this.$(window).resize(function () {
                that.logindivresize()
                //菜单导航大小改变
                that.showMenuScrollBtn()
            })

            //登录窗定时更换背景
            that.changeBg()
            //
        }
    },
    async mounted() {
        //渲染htm后
        if (this.isPortal) {
            //加载OEM信息
            await this.loadOEMInfo()
            //背景动画
            this.$("#bgimage").animate({ height: "550px" })

            // 判断当前id跟上一次存的id是否一致
            if (
                window.sessionStorage.getItem("custGlobalId") &&
                window.sessionStorage.getItem("lastCustGlobalId") &&
                window.sessionStorage.getItem("custGlobalId") === window.sessionStorage.getItem("lastCustGlobalId")
            ) {
                this.gotoStatus = true
            }

            //检查登录情况
            await this.checkLogin()
            //读取子系统列表
            //if (this.loginInfo.login) this.loadSYSlist();
            this.randCode()

            //登录类型设置
            if (window.localStorage.getItem("logintype") != null) this.logintype.cur = JSON.parse(window.localStorage.getItem("logintype"))
            if (this.logintype.cur == null || this.logintype.cur.type == null) this.logintype.cur = this.logintype.qrcode

            if (this.logintype.cur.type != "qrcode" && !this.loginInfo.login) {
                this.getCaptchaAppletQr()
                this.monitorCaptchaAppletQrState()
            }

            var that = this
            this.$(document).on("mouseover", ".layout-nav .ivu-menu-submenu", function () {
                // console.log(that.$(this).offset().left);
                that.$(this).find(".ivu-select-dropdown").css("margin-left", that.$(this).offset().left)
            })
        }

        window.vue = this
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
        menuList: function () {
            //菜单加载完成改变大小
            if (this.isPortal) setTimeout(this.menuResize, 1000)
        },
    },
    methods: {
        searchBtn() {
            this.getOrgList(this.current)
        },
        restBtn() {
            this.keyword = ""
            this.getOrgList(this.current)
        },
        async selectMenu(name) {
            this.current = name
            await this.getOrgList(this.current)
        },
        // 修改机构确定按钮
        orgSave() {
            if (this.orgSelect != null) {
                let orgDetail = this.rightList[this.orgSelect]
                window.sessionStorage.setItem("dimensionId", orgDetail.dimensionId)
                parent.loadOrgMenu(orgDetail.orgCode, "")
                this.orgStatusFn(false)
                window.sessionStorage.setItem("preUrl", "/homepage")
            }
        },
        // 超级管理员切换机构
        changeOrg() {
            this.orgStatusFn(true)
            this.orgSelect = null
            this.getOrgCode()
        },
        async getOrgCode() {
            await this.$get("/gateway/apps/org/org/app/dimension/selectByOemCodeExceptOne", {
                oemCode: "zjsm",
            }).then(async res => {
                this.orgList = res.dataList
                this.selectMenu(res.dataList[0].id)
                this.$nextTick(() => {
                    this.$refs.menu.updateActiveName()
                })
            })
        },
        async getOrgList(dimensionId) {
            this.$Message.loading({
                content: "正在加载",
            })
            await this.$get("/gateway/apps/org/org/app/organization/searchIndexOrgList", {
                dimensionId,
                orgCode: "",
                latitude: "",
                longitude: "",
                oemCode: "zjsm",
                keyword: this.keyword,
                pageSize: "10000",
                sort: "",
                service: "",
                terminalType: "H5",
            }).then(res => {
                this.$Message.destroy()
                this.rightList = res.dataList
            })
        },
        orgStatusFn(status) {
            this.orgStatus = status
        },
        // 如果是接口报错则弹窗提示
        whyOpenMpdal() {
            this.$Modal.confirm({
                title: "重置密码提示",
                content: "您的密码已经长时间未更新或未设置，为了保证账号安全，请设置密码后重新登录。",
                okText: "立即设置",
                onOk: () => {
                    this.modalStatusFn(true)
                },
            })
        },
        // 点击获取验证码按钮
        async getCaptcha() {
            if (!this.editLogin.mobile || this.editLogin.mobile == "") {
                this.$Message.warning({ content: "请先填写手机号", background: true })
                return
            }
            await this.getCaptchaCode()
            const TIME_COUNT = 60
            if (!this.timerDown) {
                this.captchaTitle = TIME_COUNT
                this.showBtn = false
                this.timerDown = setInterval(() => {
                    if (this.captchaTitle > 0 && this.captchaTitle <= TIME_COUNT) {
                        this.captchaTitle--
                    } else {
                        this.showBtn = true
                        clearInterval(this.timerDown)
                        this.timerDown = null
                        this.captchaTitle = "重新获取验证码"
                    }
                }, 1000)
            }
        },
        // 密码框密码修改事件
        changePassword() {
            if (this.editLogin.password != "" && this.modalStatus) {
                this.$core.debounce(
                    () => {
                        this.testPassword(this.editLogin.password)
                    },
                    "timer",
                    1000
                )
            }
        },
        testPassword(val) {
            var testPassword = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,64}$/
            return (this.showPasswordTip = !testPassword.test(val))
        },
        // 弹窗保存按钮
        saveModal() {
            this.$refs.editLogin.validate(async valid => {
                if (valid) {
                    this.changePassword()
                    if (!this.showPasswordTip) {
                        this.editLoading = true
                        await this.saveForgetPwd()
                    }
                } else {
                    return
                }
            })
        },
        // 弹窗关闭事件
        modalStatusFn(status) {
            this.editLogin.password = ""
            this.editLogin.captcha = ""
            this.showPasswordTip = false
            this.editLoading = false
            if (status) {
                if (this.formLogin.mobile && this.formLogin.mobile != "") this.editLogin.mobile = this.formLogin.mobile
                if (!this.timerDown) this.captchaTitle = "获取验证码"
            }
            this.modalStatus = status
        },
        // 获取验证码接口
        async getCaptchaCode() {
            await this.$post("/gateway/api/lfcommon/app/sms/send", {
                phoneNum: this.editLogin.mobile,
                action: "SD_UPDATAPWD_MSG",
                oemCode: "zjsm",
                paramString: '{"code":null,"product":"社区随约服务网上驿站"}',
            }).then(res => {
                if (!res.code || res.code != 200) {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 修改密码
        async saveForgetPwd() {
            await this.$post("/gateway/api/lfuser/user/forgetPwd", {
                mobile: this.editLogin.mobile,
                password: this.editLogin.password,
                code: this.editLogin.captcha,
            }).then(res => {
                this.editLoading = false
                if (res.code && res.code == 200) {
                    this.$Message.success({
                        content: "密码修改成功",
                        background: true,
                    })
                    this.modalStatus = false
                    this.formLogin.password = this.editLogin.password
                    return
                } else {
                    this.$Message.error({
                        content: res.desc + "，请重新输入验证码进行修改",
                        background: true,
                    })
                    this.editLogin.captcha = ""
                    return
                }
            })
        },
        getProxy() {
            return proxy
        },
        fetchData() {
            parent.history.pushState({}, 0, location.href) ///////////////////////////////////////////////
        },
        goto(url) {
            console.log("进了goto界面", url)
            //在编辑区打开页面
            if (url == null || url == "") url = "/homepage"
            this.frameUrl = url
            if (self == top) {
                try {
                    console.log("跳转进-----" + this.frameUrl)
                    this.$refs.mainFrame.contentWindow.vue.goto(this.frameUrl)
                } catch (e) {
                    if (this.frameUrl.match(/(\:\/\/)|^(\/\#)/) == null) {
                        this.frameUrl = "/#" + this.frameUrl
                    }
                    console.log("跳转iframe", this.frameUrl)
                    this.$nextTick(() => {
                        this.$refs.mainFrame.contentWindow.location.href = this.frameUrl
                    })
                }
            } else {
                if (this.frameUrl.match(/(\:\/\/)|(\.html$)|(\.html\?)|(\.htm$)|(\.htm\?)|(\.jsp$)|(\.jsp\?)|(\.action$)|(\.action\?)|(\.ac$)|(\.ac\?)|(&whitelist)/g) == null) {
                    if (this.frameUrl.match(/\/#/) != null) {
                        this.frameUrl = this.frameUrl.replace(/\/#/g, "")
                    }
                    //是否相同页面
                    var furl = this.frameUrl.indexOf("?") > 1 ? this.frameUrl.substring(0, this.frameUrl.indexOf("?")) : this.frameUrl
                    var lurl = (location.href + "").substring((location.href + "").lastIndexOf("/"))
                    lurl = lurl.indexOf("?") > 1 ? lurl.substring(0, lurl.indexOf("?")) : lurl

                    console.log("需要进行处理跳转", lurl)
                    this.$router.push(this.frameUrl)

                    //console.log(lurl+"===="+furl);
                    //是否相同页面
                    if (lurl == furl) {
                        this.$router.go(0)
                    }
                } else {
                    console.log("直接跳转", this.frameUrl)
                    location.href = this.frameUrl
                }
            }
        },
        checkPortal() {
            //检查是否门户框架
            var res = false

            if (self == top) {
                this.isPortal = true
                res = true
            } else {
                this.isPortal = false
            }

            //开发环境 + &dev=true 或 全屏控制fullscreen=true 快速响应处理
            if (this.isPortal && !this.$core.getUrlParam("dev") && !this.$core.getUrlParam("fullscreen")) {
                this.showPortal = true //带地址的开发环境
            }

            return res
        },
        portalCheckRight(loginInfo, link) {
            //编辑区检查权限
            this.preUrl = link //上一地址
            if (this.preUrl && this.preUrl != "") {
                window.sessionStorage.setItem("preUrl", this.preUrl)
                console.log("this.preUrl", this.preUrl)
            }
            if (!loginInfo.login) {
                this.loginInfo.login = loginInfo.login
                this.loginInfo.msg = loginInfo.msg
                this.checkOut(false)
            } else if (!loginInfo.right) {
                if (link != this.norightUrl) {
                    this.goto(this.norightUrl)
                }
            }
        },
        checkOut(again) {
            // console.log('checkout');
            var that = this
            if (again) {
                //重新登录控制
                this.loginAgain = true //重新登录标识，登录后直接跳转到preUrl
                this.loginInfo = {
                    login: false, //登录成功与否：true or false
                    right: false, //功能访问权限：true or false
                    msg: "请重新登录。", //返回消息，失败原因等
                    userinfo: {},
                }
            }
            this.$Message.destroy()
            this.$Message.error(
                {
                    background: true,
                    content: that.loginInfo.msg,
                    duration: 3,
                },
                false
            )
            let layout = document.getElementsByClassName("login_layout")
            let sysList = document.getElementById("syslistWin")
            if (layout[0].style.display == "none") {
                layout[0].style.display = ""
            }
            if (sysList.style.display == "") {
                sysList.style.display = "none"
            }
            that.$("#syslistWin").hide()
            that.$(".login_layout").show()
            that.$("#loginWin").show().removeClass("bounceOut animated").addClass("bounceIn animated")

            // 显示客服模块
            this.$refs.service_box.style.opacity = "1"

            if (this.logintype.cur.type == "qrcode") {
                this.randCode()
            } else {
                this.getCaptchaAppletQr()
                this.monitorCaptchaAppletQrState()
            }
        },
        async loadOEMInfo() {
            //获取OEM的配置信息接口
            //  await this.$get("/testjson/portal/oeminfo.json")
            await this.$get("/gateway/apps/basic/api/base/finger/tenantPortalConfig/getByDomain")
                .then(res => {
                    // var res = {
                    //     code: "200",
                    //     desc: "success",
                    //     data: {
                    //         bgimages: ["https://property-liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm_oldpeople/20210224/202102241701029SFPutxM.png"],
                    //         copyright: "广州列丰信息科技有限公司技术支持",
                    //         domain: "https://suiyue.liefengtech.com",
                    //         gmtCreate: 1603695372000,
                    //         gmtModified: null,
                    //         headerBgcolor: "#F7F8F2",
                    //         headerFontcolor: "#666666",
                    //         id: 1,
                    //         leftMenuBgcolor: "#FFF5F3",
                    //         leftMenuFontcolor: "#999999",
                    //         leftMenuSelecteFontcolor: "#FFFFFF",
                    //         leftMenuSelectedBgcolor: "#23B16F",
                    //         leftMenuitemBgcolor: "#2d8cf0",
                    //         loginLogo: "https://property-liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm_oldpeople/20210224/2021022417092200PvYYMj.png",
                    //         oemCode: "zjsm",
                    //         oemName: "社区随约服务网上驿站",
                    //         tenantId: "8a9dc64d67641ba30167881bbb8c5e1f",
                    //     },
                    // }
                    if (res && res.data && res.data.oemCode != null) {
                        this.oemInfo = res.data
                        this.$("title").html(this.oemInfo.oemName)
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "没有读取到门户信息，请重试。",
                            duration: 3,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "读取门户信息时出错。",
                        duration: 3,
                    })
                })
        },
        async checkLogin() {
            if (this.$core.ifNULLtoDF(window.sessionStorage.getItem("custGlobalId"), null) == null) return
            if (this.$core.ifNULLtoDF(window.sessionStorage.getItem("accessToken"), null) == null) return
            if (this.$core.ifNULLtoDF(window.sessionStorage.getItem("systemList"), null) == null) return
            else {
                this.loginInfo.login = true
                this.loginInfo.userinfo.systemList = []
                this.loginInfo.userinfo = JSON.parse(window.sessionStorage.getItem("userInfo"))
                this.loginInfo.userinfo.token = window.sessionStorage.getItem("accessToken")
                this.loginInfo.userinfo.orgCode = this.$core.ifNULLtoDF(window.sessionStorage.getItem("orgCode"), "")
                this.loginInfo.userinfo.systemList = this.$core.ifNULLtoDF(JSON.parse(window.sessionStorage.getItem("systemList")), [])
                await this.loadMENUlist(true)
                this.loadSYSlist()
            }
        },
        changeBg() {
            //更换背景图片
            var that = this
            if (that.oemInfo.bgimages.length > 1) {
                that.bg_i += 1
                that.bg_i = that.bg_i % that.oemInfo.bgimages.length
                that.$("#bgimage").fadeTo(500, 0.1, function () {
                    that.$(this).css("background-image", "url(" + that.oemInfo.bgimages[that.bg_i] + ")")
                    that.$(this).fadeTo(500, 1)
                })
            }
            setTimeout(that.changeBg, 10000)
        },
        login(isQrcode, serialNumber) {
            //登录接口
            var that = this
            this.$refs.formLogin.validate(valid => {
                if (valid) {
                    this.loginloading = true
                    var logininfo = {
                        login: false, //登录成功与否：true or false
                        right: false, //功能访问权限：true or false
                        msg: "验证失败。", //返回消息，失败原因等
                        userinfo: {},
                    }

                    let data = {}
                    // 如果是扫码登录
                    if (!isQrcode) {
                        data = {
                            oemCode: this.oemInfo.oemCode,
                            appCode: "suiyue_pc",
                            loginType: "10",
                            serialNumber: serialNumber ? serialNumber : "",
                        }
                    } else {
                        data = {
                            account: this.formLogin.mobile,
                            password: this.formLogin.password,
                            oemCode: this.oemInfo.oemCode,
                            appCode: "suiyue_pc",
                            loginType: "9",
                            captchaId: this.captchaId,
                            captchaCode: this.formLogin.captcha,
                        }
                    }

                    // 非扫码登录
                    //  this.$post("/basic/api/base/userLogin/loginByWorkbench", data)
                    this.$post("/gateway/api/oauth/Login?client_id=suianju&client_secret=1234567&scope=all&grant_type=password", data, { "Context-type": "application/json", Authorization: "" })
                        .then(async res => {
                            // 获取token接口
                            if (res.code == 200) {
                                if (res.data) {
                                    let userinfoRes = await this.$get("/gateway/api/oauth/UserInfo", { token: res.data.token }, { Authorization: res.data.token })

                                    // // 测试用，将systemList设为[]
                                    // userinfoRes.data.systemList = []

                                    if (userinfoRes.code == 200 && userinfoRes.data && userinfoRes.data.custGlobalId) {
                                        this.loginloading = false
                                        if (userinfoRes.data.systemList && userinfoRes.data.systemList.length) {
                                            userinfoRes.data.systemList.map(item => {
                                                if (item.sysConfigMap && item.sysConfigMap) {
                                                    let data = JSON.parse(item.sysConfigMap.IndexParam)
                                                    item.sysConfigMap = data
                                                }
                                            })
                                            window.sessionStorage.setItem("systemList", JSON.stringify(userinfoRes.data.systemList))
                                        }
                                        userinfoRes.data.realName = userinfoRes.data.realName ? userinfoRes.data.realName : userinfoRes.data.userName ? userinfoRes.data.userName : ""
                                        userinfoRes.data.avatarUrl = userinfoRes.data.portraitImg ? userinfoRes.data.portraitImg : ""
                                        window.sessionStorage.setItem("userInfo", JSON.stringify(userinfoRes.data))
                                        logininfo = {
                                            login: true, //登录成功与否：true or false
                                            right: true, //功能访问权限：true or false
                                            msg: "", //返回消息，失败原因等
                                            userinfo: userinfoRes.data,
                                        }
                                        logininfo.userinfo["token"] = res.data.token
                                        this.custGlobalId = userinfoRes.data.custGlobalId
                                        that.loginInfo = logininfo
                                        if (this.custGlobalId == undefined) {
                                            window.sessionStorage.removeItem("custGlobalId")
                                            window.sessionStorage.removeItem("lastCustGlobalId")
                                        } else {
                                            window.sessionStorage.setItem("custGlobalId", this.custGlobalId)
                                            if (window.sessionStorage.getItem("lastCustGlobalId") && window.sessionStorage.getItem("lastCustGlobalId") != "") {
                                                if (window.sessionStorage.getItem("lastCustGlobalId") == window.sessionStorage.getItem("custGlobalId")) {
                                                    this.gotoStatus = true
                                                }
                                            }
                                            window.sessionStorage.setItem("lastCustGlobalId", this.custGlobalId)
                                        }
                                        if (that.loginInfo.userinfo.token == undefined) {
                                            window.sessionStorage.removeItem("accessToken")
                                        } else {
                                            window.sessionStorage.setItem("accessToken", that.loginInfo.userinfo.token)
                                        }
                                        if (that.loginInfo.userinfo.mobile == undefined) {
                                            window.sessionStorage.removeItem("mobile")
                                        } else {
                                            window.sessionStorage.setItem("mobile", that.loginInfo.userinfo.mobile)
                                        }

                                        if (that.loginInfo.userinfo.openId == undefined) {
                                            window.sessionStorage.removeItem("openId")
                                        } else {
                                            window.sessionStorage.setItem("openId", that.loginInfo.userinfo.openId)
                                        }

                                        if (!userinfoRes.data.systemList || !userinfoRes.data.systemList.length) {
                                            this.$Modal.confirm({
                                                title: "权限异常",
                                                content: "账号权限获取异常，请点击更新权限并重新登录。",
                                                okText: "更新权限",
                                                onOk: () => {
                                                    this.$post("/gateway/api/lfuser/user/account/flushAuth").then(res => {
                                                        if (res.code && res.code == 200) {
                                                            this.$Message.success({
                                                                content: "更新权限成功，请重新登录",
                                                                background: true,
                                                            })
                                                            this.loginInfo.login = null
                                                            window.location.reload()
                                                            return
                                                        } else {
                                                            this.$Message.error({
                                                                content: "更新权限失败，请重新登录",
                                                                background: true,
                                                            })
                                                            this.loginInfo.login = null
                                                            window.location.reload()
                                                            return
                                                        }
                                                    })
                                                },
                                            })
                                        }
                                    } else {
                                        that.loginInfo.msg = userinfoRes.msg ? userinfoRes.msg : userinfoRes.desc ? userinfoRes.desc : "登陆失败"
                                    }
                                } else {
                                    that.loginInfo.msg = res.desc ? res.desc : res.msg ? res.msg : "登陆失败"
                                }
                            } else {
                                // 登陆失败之后判断是否是扫码登录，是的话就获取新的二维码
                                if (!this.isQrcode) {
                                    this.getCaptchaAppletQr()
                                    this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                                    if (res.code == "CAPTCHA_CODE_FAIL" || res.code == "USER_PASSWORD_WRONG" || res.code == "PASSWD_IS_OVERDUE" || res.code == "CUST_LOGINACCOUNT_LOCK")
                                        that.loginInfo.msg = res.desc
                                    else if (res.code == "CUST_LOGIN_ACCOUNT_NOT_EXIST") that.loginInfo.msg = "您的账号还未开通，请联系管理员"
                                    else that.loginInfo.msg = "登录失败，请重试"

                                    that.formLogin.captcha = ""
                                } else {
                                    // 不是显示报错信息
                                    that.loginInfo.msg = res.desc ? res.desc : res.msg ? res.msg : "登陆失败"
                                }
                            }

                            if (that.loginInfo.login) {
                                //加载子系统列表
                                this.loadSYSlist()
                                return
                            } else {
                                //登录失败
                                this.$Message.error({
                                    background: true,
                                    content: that.loginInfo.msg,
                                    duration: 3,
                                })
                            }
                            this.randCode()
                            this.loginloading = false
                            if (this.loginInfo.login && this.loginAgain) {
                                //重新登录则直接跳转回页面
                                that.$("#syslistWin").hide()
                                that.$(".login_layout").fadeOut(500)
                                that.$("#loginWin").hide()
                                // 隐藏客服模块
                                this.$refs.service_box.style.opacity = "0"
                            }
                        })
                        .catch(err => {
                            console.log("err", err)
                            this.randCode()
                            that.loginloading = false
                            logininfo.msg = "验证出错"
                            that.loginInfo = logininfo
                            window.sessionStorage.removeItem("lastCustGlobalId")
                            window.sessionStorage.removeItem("custGlobalId")
                            window.sessionStorage.removeItem("accessToken")
                            window.sessionStorage.removeItem("openId")
                            window.sessionStorage.removeItem("userInfo")
                            window.sessionStorage.removeItem("systemList")
                            window.sessionStorage.removeItem("userDetail")
                            window.sessionStorage.removeItem("lastAppCode")
                            window.sessionStorage.removeItem("thisAppCode")

                            this.$Message.error({
                                background: true,
                                content: that.loginInfo.msg,
                                duration: 3,
                            })
                        })
                }
            })
        },
        logout() {
            //退出登录
            var that = this
            this.$Modal.confirm({
                title: "退出提示",
                content: "<p>正在执行退出门户，是否继续？</p>",
                loading: true,
                onOk: () => {
                    //退出子系统
                    var domain = proxy["/oldpeople"]
                    this.$("#loginfrm").attr("src", domain.target + "/api/uc/logout.ac")

                    //调用后台退出登录接口
                    this.$get("/gateway/apps/basic/api/base/userLogin/logoutByWorkbench", { token: window.sessionStorage.getItem("accessToken") }).then(res => {
                        window.sessionStorage.removeItem("custGlobalId")
                        window.sessionStorage.removeItem("accessToken")
                        window.sessionStorage.removeItem("openId")
                        window.sessionStorage.removeItem("userInfo")
                        window.sessionStorage.removeItem("systemList")
                        window.sessionStorage.removeItem("userDetail")
                        this.$Modal.remove()
                        if (res && res.code == "200") {
                            window.location.reload()
                        } else {
                            this.$Modal.remove()
                        }
                    })
                },
            })
        },
        loadSYSlist() {
            //获取子系统列表接口logoutlotlotdsfdsfsdfsdf
            var that = this

            //子系统列表在登录时获取，则写至子系统列表中
            if (this.loginInfo.userinfo.systemList && this.loginInfo.userinfo.systemList.length > 0) {
                this.sysList = this.loginInfo.userinfo.systemList
                //   this.sysList.push(this.loginInfo.userinfo.systemList[0]);////测试多个子系统时，这里复制成多个，需要删除掉++++++++++++++++++++++++++++++++++++++++++++++++++
            }

            //构建子系统列表页
            that.$("#loginWin").hide()
            // 隐藏客服模块
            this.$refs.service_box.style.opacity = "0"

            that.$("#syslistWin").show().animate({ width: "80%", height: "80%" }, that.logindivresize)
            if (that.sysList && that.sysList.length == 1) {
                //单个子系统直接进入
                that.clicksystem(0)
            }
        },
        async loadMENUlist(reLogin) {
            //登录并获取子系统菜单队列接口
            let back = false
            let param = {}
            param.accessToken = this.$core.ifNULLtoDF(this.loginInfo.userinfo.token, "")
            param.oemCode = this.$core.ifNULLtoDF(this.oemInfo.oemCode, "")
            param.orgCode = this.$core.ifNULLtoDF(this.loginInfo.userinfo.orgCode, "")
            param.time = new Date().getTime()
            this.$Message.destroy()
            this.$Message.loading({ content: "正在加载菜单...", duration: 0 })

            if (!reLogin) {
                var domain = proxy["/oldpeople"]
                // console.log(domain.target);
                var url = "/oldpeople/api/uc/v2/selectStaffMenu.ac"
                // if (this.currentSystem && this.currentSystem.appCode != "suiyue_pc") {
                if (this.loginInfo && this.loginInfo.userinfo.oemCode != "zjsm") {
                    url = this.currentSystem.sysConfigMap.menuApi
                    window.sessionStorage.setItem("preUrl", this.currentSystem.sysConfigMap.sysHomePage)
                }
                await this.$("#loginfrm").attr("src", domain.target + "/api/uc/v2/selectStaffMenu.ac?accessToken=" + param.accessToken + "&oemCode=" + param.oemCode + "&orgCode=" + param.orgCode) /////////////////////////////
                try {
                    await this.$get(url, param)
                        .then(async res => {
                            console.log("获取的菜单接口数据", res)
                            if (res.code == "200") {
                                this.menuList = res.dataList
                                this.loginInfo.userinfo.id = res.staffId
                                this.loginInfo.userinfo.orgCode = res.orgCode || res.orgUserVOS[0].orgCode
                                this.loginInfo.userinfo.orgName = res.orgName
                                this.loginInfo.userinfo.orgUserVOS = res.orgUserVOS
                                this.loginInfo.userinfo.adminType = res.adminType
                                this.loginInfo.userinfo.userDepName = res.userDepName
                                this.loginInfo.userinfo.communityCode = res.staffScopeCommunityCode
                                this.loginInfo.userinfo.orgCat = res.orgCat
                                window.sessionStorage.setItem("orgCode", res.orgCode || res.orgUserVOS[0].orgCode)
                                window.sessionStorage.setItem("staffId", res.staffId)
                                window.sessionStorage.setItem("userDetail", JSON.stringify(res))
                                window.sessionStorage.setItem("orgCat", res.orgCat)
                                this.loginInfo.login = true
                                this.$core.setUserInfoOrgCode(res.orgCode)
                                //  this.$Message.destroy();
                                console.log("获取个人信息之后开始进入系统", this.loginInfo)
                                await this.getButtonRoot()
                                back = true
                            } else {
                                this.$Message.destroy()
                                this.$Message.error({ background: true, content: res.desc, duration: 3 })
                            }
                        })
                        .catch(e => {
                            console.log("获取菜单信息信息接口报错")
                            this.$Message.destroy()
                        })
                } catch (err) {
                    console.log("获取错误信息", err)
                }
            }
            this.$Message.destroy()
            return back
        },
        goSubmenu(index, url, menuid, item) {
            if (item && item.desc && item.desc != "") this.menuDesc = item.desc
            else this.menuDesc = ""
            let params = {
                oemCode: this.oemInfo.oemCode,
                orgCode: this.loginInfo.userinfo.orgCode,
                statType: 0,
                custGlobalId: this.custGlobalId,
                menuCode: item && item.menuCode && item.menuCode != "" ? item.menuCode : "" || "",
                columnCode: item && item.columnCode && item.columnCode != "" ? item.columnCode : "" || "",
            }
            this.menuPost(params)
            if (index < 0 && index >= this.menuList.length) return
            var menu = this.menuList[index]
            this.subMenus = menu.subMenus
            if (this.subMenus && this.subMenus != null && this.subMenus.length > 0) {
                this.menustate = {
                    class: "ios-arrow-forward",
                    title: "点击显示侧边栏",
                } //侧边栏控制
            } else {
                this.menustate = {
                    class: "ios-arrow-back",
                    title: "点击隐藏侧边栏",
                } //侧边栏控制
            }
            this.showleftMenu()

            var url2 = this.$core.ifNULLtoDF(url, menu.url)
            if (this.$core.ifNULLtoDF(url2, null) != null) {
                this.goto(url2)
            }

            if (this.$core.ifNULLtoDF(menuid, null) != null) {
                var that = this
                var tar1 = that.$("#leftmenuarea").find(".S" + menuid)
                if (tar1 && tar1.html() != undefined && tar1.html() != null) {
                    tar1.click()
                } else {
                    setTimeout(function () {
                        var tar2 = that.$("#leftmenuarea").find(".S" + menuid)
                        tar2.click()
                    }, 1000)
                }
            }
        },
        syncTopMenu(url, menuid, item) {
            if (item && item.desc && item.desc != "") this.menuDesc = item.desc
            else this.menuDesc = ""
            if (this.$core.ifNULLtoDF(menuid, null) != null) {
                this.$(".layout-nav .S" + menuid).click()
            }
            this.goto(url)
        },
        closeLoginForm() {
            //关闭登录和子系统列表窗体
            var that = this
            this.$("#loginWin").hide()
            that.$("#syslistWin").hide()
            // 隐藏客服模块
            this.$refs.service_box.style.opacity = "0"
            // .show()
            // .removeClass("bounceIn animated")
            // .addClass("bounceOut animated")
            // .one("animationend oAnimationEnd webkitAnimationEnd mozAnimationEnd MSAnimationEnd", function () {
            //     that.$(this).hide()
            // })
            that.$(".login_layout").fadeOut(1000)
        },
        showOEMlistForm() {
            //显示子系统列表窗体
            var that = this
            //退出子系统
            var domain = proxy["/gateway"]
            this.$("#loginfrm").attr("src", domain.target + "/api/uc/logout.ac")
            that.$(".login_layout").show()
            that.$("#loginWin").hide()
            // 隐藏客服模块
            this.$refs.service_box.style.opacity = "0"

            that.$("#syslistWin")
                .show()
                .removeClass("bounceOut animated")
                .addClass("bounceIn animated")
                .one("animationend oAnimationEnd webkitAnimationEnd mozAnimationEnd MSAnimationEnd", function () {
                    that.logindivresize()
                })
        },
        async clicksystem(index) {
            //获取地址栏指定的地址
            this.currentSystem = this.sysList[index]
            this.appCode = this.sysList[index].appCode
            window.sessionStorage.setItem("thisAppCode", this.appCode)
            var flag = false
            if (window.sessionStorage.getItem("lastAppCode") && window.sessionStorage.getItem("lastAppCode") != "") {
                if (window.sessionStorage.getItem("thisAppCode") != window.sessionStorage.getItem("lastAppCode")) {
                    flag = true
                }
            }
            //第三方系统弹窗打开。
            if (this.currentSystem.sysConfigMap && JSON.parse(this.currentSystem.sysConfigMap.isThirdSystem)) {
                window.open(this.currentSystem.sysConfigMap.domain)
                return
            }

            var link = this.currentSystem.sysConfigMap.sysHomePage
            console.log(link + "!!!!!!!!!")
            //如果重新登录状态，则调转回原地址
            if (this.loginAgain) {
                if (this.preUrl != null && this.preUrl != "") link = this.preUrl
                this.loginAgain = false
                this.preUrl = ""
            } else {
                //优先使用地址栏的路径，开发时使用，以后可以考虑去掉====================================================
                // let url = window.location.href;
                // // console.log(url);
                // url = url.substring(url.indexOf("/", 8));
                // if (url != "/"&&url != "/#/") link = url;
            }

            //登录并获取子系统菜单
            await this.loadMENUlist()
            //console.log(this.menuList);
            if (this.menuList.length == 0) {
                // this.$Message.destroy();
                // this.$Message.error({
                //         background: true,
                //         content: "登录子系统失败，请重试。",
                //         duration: 3,
                //       });
                return
            }
            this.menustate = {
                class: "ios-arrow-back",
                title: "点击隐藏侧边栏",
            } //侧边栏控制
            this.showleftMenu()
            if (this.gotoStatus) {
                if (flag) {
                    window.sessionStorage.setItem("preUrl", link)
                } else {
                    if (window.sessionStorage.getItem("preUrl") && window.sessionStorage.getItem("preUrl") != "" && window.sessionStorage.getItem("preUrl").indexOf("/noRight") > -1) {
                        console.log("同一个账号走这里1", window.sessionStorage.getItem("preUrl"))
                        window.sessionStorage.setItem("preUrl", "/homepage")
                    } else if (!window.sessionStorage.getItem("preUrl") || window.sessionStorage.getItem("preUrl") == "") {
                        console.log("同一个账号走这里2", window.sessionStorage.getItem("preUrl"))
                        window.sessionStorage.setItem("preUrl", "/homepage")
                    } else {
                        console.log("同一个账号走这里3", window.sessionStorage.getItem("preUrl"))
                    }
                }
                console.log("最终跳转的url", window.sessionStorage.getItem("preUrl"))
                this.goto(window.sessionStorage.getItem("preUrl"))
                window.sessionStorage.setItem("lastAppCode", this.appCode)
            } else {
                this.goto(link)
            }
            this.$("#menustyle").remove()

            this.$(
                "<style id='menustyle' scoped>" +
                    ".ivu-menu-dark{background:" +
                    this.currentSystem.sysConfigMap.leftMenuBgcolor +
                    "}" +
                    ".ivu-menu-item-group-title{height:30px;line-height:30px;padding-left:8px;font-size:12px;color:#999}" +
                    ".ivu-menu-vertical .ivu-menu-item:hover,.ivu-menu-vertical .ivu-menu-submenu-title:hover{color:" +
                    this.currentSystem.sysConfigMap.leftMenuSelectedBgcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-item-group-title{color:" +
                    this.currentSystem.sysConfigMap.leftMenuFontcolor +
                    ";opacity:0.5}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-item,.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title{color:" +
                    this.currentSystem.sysConfigMap.leftMenuFontcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu),.ivu-menu-dark.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):hover,.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu),.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu):hover{background:" +
                    this.currentSystem.sysConfigMap.leftMenuSelectedBgcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-item:hover,.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title:hover{color:" +
                    this.currentSystem.sysConfigMap.leftMenuFontcolor +
                    ";background:" +
                    this.currentSystem.sysConfigMap.leftMenuBgcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu),.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu){color:" +
                    this.currentSystem.sysConfigMap.leftMenuSelecteFontcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item:hover{color:" +
                    this.currentSystem.sysConfigMap.leftMenuFontcolor +
                    ";background:0 0!important}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active,.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active:hover{border-right:none;color:" +
                    this.currentSystem.sysConfigMap.leftMenuSelecteFontcolor +
                    ";background:" +
                    this.currentSystem.sysConfigMap.leftMenuSelectedBgcolor +
                    "!important}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-child-item-active>.ivu-menu-submenu-title{color:" +
                    this.currentSystem.sysConfigMap.leftMenuFontcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-opened{background:" +
                    this.currentSystem.sysConfigMap.leftMenuitemBgcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-opened .ivu-menu-submenu-title{background:" +
                    this.currentSystem.sysConfigMap.leftMenuBgcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-opened .ivu-menu-submenu-has-parent-submenu .ivu-menu-submenu-title{background:0 0}" +
                    ".ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item,.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu{color:rgba(255,255,255,.7)}" +
                    ".ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item-active,.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item:hover,.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu-active,.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu:hover{color:#fff}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item{margin:0;line-height:normal;padding:0px;clear:both;color:#515a6e;font-size:14px!important;white-space:nowrap;list-style:none;cursor:pointer;-webkit-transition:background .2s ease-in-out;transition:background .2s ease-in-out}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item:hover{background:#f3f3f3}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown{left:0!important;transform-origin:left top!important;position:fixed!important;max-height:calc(100vh - 100px);}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-focus{background:#f3f3f3}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-disabled{color:#c5c8ce;cursor:not-allowed}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-disabled:hover{color:#c5c8ce;background-color:#fff;cursor:not-allowed}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-selected,.ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-selected:hover{color:#2d8cf0}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-divided{margin-top:5px;border-top:1px solid #e8eaec}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-divided:before{content:'';height:5px;display:block;margin:0 -16px;background-color:#fff;position:relative;top:-7px}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-enter{color:#2d8cf0;font-weight:700;float:right}" +
                    ".ivu-menu-horizontal.ivu-menu-light:after{content:'';display:block;width:100%;height:1px;background:#dcdee2;position:absolute;bottom:0;left:0}" +
                    ".ivu-menu-vertical.ivu-menu-light:after{content:'';display:block;width:1px;height:100%;background:#dcdee2;position:absolute;top:0;bottom:0;right:0;z-index:1}" +
                    ".ivu-menu-primary{background:" +
                    this.currentSystem.sysConfigMap.headerBgcolor +
                    "}" +
                    ".ivu-menu-primary.ivu-menu-horizontal .ivu-menu-item,.ivu-menu-primary.ivu-menu-horizontal .ivu-menu-submenu{color:" +
                    this.currentSystem.sysConfigMap.headerFontcolor +
                    "}" +
                    ".ivu-menu-primary.ivu-menu-horizontal .ivu-menu-item-selected,.ivu-menu-primary.ivu-menu-horizontal .ivu-menu-item-active>.ivu-menu-submenu-title{color:" +
                    this.currentSystem.sysConfigMap.headerSelectedFontcolor +
                    ";background:" +
                    this.currentSystem.sysConfigMap.headerSelectedBgcolor +
                    ";}" +
                    ".ivu-menu-vertical .ivu-menu-item, .ivu-menu-vertical .ivu-menu-submenu-title {padding: 0px;}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu-title {display: flex;flex-wrap: nowrap;align-items: center;}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu-title-icon {margin-left:5px;}" +
                    "</style>"
            ).appendTo("head")

            this.$("title").html(this.currentSystem.systemName)
            this.closeLoginForm()
        },
        menuResize() {
            //重置头菜单宽度大小
            //  console.log(this.$(".layout-logo").outerWidth()+this.$(".layout-right").outerWidth());
            // console.log(this.$(".layout-nav").width());
            //this.$(".layout-nav").css("width","calc(100% - "+(this.$(".layout-logo").outerWidth()+this.$(".layout-right").outerWidth()+100)+"px)");
            this.layoutnavWidth = "calc(100% - " + (this.$(".layout-logo").outerWidth() + this.$(".layout-right").outerWidth() + 60) + "px)"
            // console.log(this.layoutnavWidth+"=============="+this.$(".layout-nav").width());
            setTimeout(this.showMenuScrollBtn, 1000)
        },
        scrollMenuList(left) {
            // this.$(".layout-nav").scrollLeft(this.$(".layout-nav").scrollLeft()+(left*this.$(".layout-nav").width())-100);
            this.$(".layout-nav")
                .stop()
                .animate({ scrollLeft: this.$(".layout-nav").scrollLeft() + left * this.$(".layout-nav").width() - left * 100 }, 500)
        },
        showMenuScrollBtn() {
            // console.log(this.$(".layout-nav").prop("scrollWidth")+"============="+this.$(".layout-nav").width());
            try {
                if (this.$(".layout-nav").width() >= this.$(".layout-nav").prop("scrollWidth")) {
                    this.showMenuScroll = false
                } else {
                    this.showMenuScroll = true
                }
            } catch (e) {}
        },
        logindivresize() {
            //窗口大小改变
            var that = this
            this.$("#systemlist").css("height", this.$("#syslistWin").height() - 100)
            var sy = Math.floor(this.$("#systemlist").width() % this.$(".sysitem").width()) - 12 //12为滚动条
            var tw = Math.floor(this.$("#systemlist").width() / this.$(".sysitem").width()) * 2
            this.$("#systemlist .sysitem").css("margin", "20px " + sy / tw + "px")
        },
        showleftMenu() {
            if (this.menustate.class == "ios-arrow-back") {
                this.$("#leftmenuarea").animate({ left: "-200px" }, 200)
                this.$(".leftmenubtn").animate({ left: "0" }, 200)
                // this.$(".leftmenubtn").css({background:this.oemInfo.leftMenuBgcolor,color:this.oemInfo.leftMenuFontcolor});
                this.$("#contentarea").animate({ left: "0" }, 200)
                this.menustate = {
                    class: "ios-arrow-forward",
                    title: "点击显示侧边栏",
                } //侧边栏控制
            } else {
                this.$("#leftmenuarea").animate({ left: "0px" }, 200)
                this.$(".leftmenubtn").animate({ left: "200px" }, 200)
                this.$("#contentarea").animate({ left: "200px" }, 200)
                this.menustate = { class: "ios-arrow-back", title: "点击隐藏侧边栏" } //侧边栏控制
            }
        },

        async getButtonRoot() {
            console.log("进入获取权限的接口")
            try {
                //获取新增/修改/审核等按钮权限
                this.$Message.destroy()
                this.$Message.loading({ content: "正在读取按钮权限...", duration: 0 })
                var that = this
                let param = {}
                param.staffId = this.loginInfo.userinfo.id
                param.orgCode = this.loginInfo.userinfo.orgCode
                var url = "/voteapi/api/pc/staff/getStaffButtonsCode"
                if (this.loginInfo && this.loginInfo.userinfo.oemCode != "zjsm") {
                    url = this.currentSystem.sysConfigMap.roleCodeApi
                }
                await this.$get(url, param)
                    .then(res => {
                        that.userButtonRoot = res.dataList
                        this.buttonRoot = that.userButtonRoot.includes("superAdmin100") ? "1003" : ""

                        window.sessionStorage.setItem("roleCodes", res.dataList)
                        this.$Message.destroy()
                    })
                    .catch(e => {
                        this.$Message.destroy()
                    })
            } catch (err) {
                console.log("获取权限接口报错", err)
            }
        },
        randCode() {
            this.randcodeAction = ""
            this.captchaId = ""
            // this.$get('/basic/api/base/captcha/captchaImage',null).then(res => {
            this.$get("/gateway/api/lfuser/captcha/captchaImage", null).then(res => {
                if (res && res.data && res.data != null) {
                    this.randcodeAction = "data:image/gif;base64," + res.data.img
                    this.captchaId = res.data.captchaId
                }
            })
        },
        loginType() {
            if (this.logintype.cur.type == "qrcode") {
                this.logintype.cur = this.logintype.compute
                this.getCaptchaAppletQr()
                this.monitorCaptchaAppletQrState()
            } else {
                this.logintype.cur = this.logintype.qrcode
                this.randCode()
            }
            window.localStorage.setItem("logintype", JSON.stringify(this.logintype.cur))
        },
        getCaptchaAppletQr() {
            this.captchaAppletQr = {}
            //  this.$get('/basic/api/base/captcha/getCaptchaAppletQr',{oemCode:this.oemInfo.oemCode}).then(res => {
            this.$get("/gateway/api/lfuser/captcha/getCaptchaAppletQr", { oemCode: this.oemInfo.oemCode }).then(res => {
                if (res && res.code == "200" && res.data != null) {
                    //       console.log(res.data);
                    this.captchaAppletQr = res.data
                }
            })
        },
        monitorCaptchaAppletQrState() {
            clearTimeout(this.monitorQRTimeout)
            if (this.logintype.cur.type != "qrcode") {
                if (this.captchaAppletQr && this.captchaAppletQr.code != null) {
                    // this.$post('/basic//api/base/userLogin/rotationAppletLoginBenchResult',{oemCode:this.oemInfo.oemCode,code:this.captchaAppletQr.code}).then(res => {
                    this.$get("/gateway/api/lfuser/weappLogin/weappCodeLoginResult", { oemCode: this.oemInfo.oemCode, code: this.captchaAppletQr.code })
                        .then(res => {
                            //   console.log(res);
                            if (res && res.code == "200" && res.data) {
                                // this.loginInfo = {
                                //     login: true, //登录成功与否：true or false
                                //     right: true, //功能访问权限：true or false
                                //     msg: "", //返回消息，失败原因等
                                //     userinfo: res.data,
                                //  };
                                // 1 等待扫码  2扫码成功  3扫码超时
                                if (res.data == "2") {
                                    this.login(false, this.captchaAppletQr.code)
                                } else if (res.data == "1") {
                                    this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                                } else if (res.data == "3") {
                                    this.getCaptchaAppletQr()
                                    this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                                    this.$Message.error({
                                        background: true,
                                        content: "小程序码已失效，已为您生成新的小程序码，请重新扫码。",
                                        duration: 3,
                                    })
                                }

                                return
                                this.loginInfo = {
                                    login: true, //登录成功与否：true or false
                                    right: true, //功能访问权限：true or false
                                    msg: "", //返回消息，失败原因等
                                    userinfo: res.data,
                                }
                                this.custGlobalId = res.data.custGlobalId
                                if (this.custGlobalId == undefined) {
                                    window.sessionStorage.removeItem("custGlobalId")
                                } else {
                                    window.sessionStorage.setItem("custGlobalId", this.custGlobalId)
                                }
                                if (this.loginInfo.userinfo.token == undefined) {
                                    window.sessionStorage.removeItem("accessToken")
                                } else {
                                    window.sessionStorage.setItem("accessToken", this.loginInfo.userinfo.token)
                                }
                                if (this.loginInfo.userinfo.mobile == undefined) {
                                    window.sessionStorage.removeItem("mobile")
                                } else {
                                    window.sessionStorage.setItem("mobile", this.loginInfo.userinfo.mobile)
                                }

                                if (this.loginInfo.userinfo.openId == undefined) {
                                    window.sessionStorage.removeItem("openId")
                                } else {
                                    window.sessionStorage.setItem("openId", this.loginInfo.userinfo.openId)
                                }
                                if (this.loginInfo.login) {
                                    //加载子系统列表
                                    this.loadSYSlist()
                                }
                            } else if (res && res.code == "QR_AUTHENTICATION_FAILED") {
                                this.getCaptchaAppletQr()
                                this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                                this.$Message.error({
                                    background: true,
                                    content: "小程序码已失效，已为您生成新的小程序码，请重新扫码。",
                                    duration: 3,
                                })
                            } else {
                                this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                            }
                        })
                        .catch(e => {
                            this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                        })
                } else {
                    this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                }
            }
        },
        showMDPsw() {
            this.modalStatusFn(true)
            // openwindow("mdpsw", "修改密码", "../jsp/platform/updatepassword/updatePassword.jsp", null, 500, 300, 100, 200, false)
        },

        async menuPost(params) {
            this.$.ajax({
                url: proxy["/gateway"].target + "/apps/oldpeople/api/pc/homepage/addCommonBrowseRecords",
                type: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    ...params,
                },
            })
        },

        download(url, name) {
            const d = this.$Message.loading("下载中...")
            const xhr = new XMLHttpRequest()
            xhr.open("GET", url, true)
            xhr.responseType = "blob"
            xhr.onload = () => {
                if (xhr.status === 200) {
                    var a = document.createElement("a")
                    var event = new MouseEvent("click")
                    a.download = name
                    a.href = URL.createObjectURL(xhr.response)
                    a.dispatchEvent(event)
                } else this.$Message.error(xhr.statusText)
                d()
            }
            xhr.send()
        },
    },
}
</script>
<style lang="less">
.login_layout {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    position: fixed;
    background-color: #fafafa;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    .service-box {
        position: fixed;
        max-width: calc(100% - 10% - 456px - 50px);
        bottom: 10px;
        left: 10px;
        background: #fff;
        border: 1px solid #e3e3e3;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        display: flex;
        overflow: auto;
        opacity: 1;
        transition: all 0.3s ease;

        .we-box {
            padding: 10px 20px;
            border-right: 1px solid #e3e3e3;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-shrink: 0;

            .title {
                font-size: 16px;
                font-weight: bold;
                text-align: center;
            }

            .qrcode {
                width: 150px;
                height: 150px;
                margin: 10px 0;

                .img {
                    width: 100%;
                    height: 100%;
                }
            }

            .phone {
                text-align: center;
            }
        }

        .apply-box {
            padding: 10px 20px;
            display: flex;
            flex-shrink: 0;

            .flow-box {
                .title {
                    font-size: 16px;
                    font-weight: bold;
                }

                .name {
                    margin: 20px 0 10px 0;
                }

                .link {
                    cursor: pointer;
                    margin-bottom: 5px;
                    color: #2db7f5;
                    text-decoration: underline;
                }
            }

            .qrcode-box {
                margin-left: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .title {
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                }

                .qrcode {
                    width: 170px;
                    height: 170px;
                    margin: 10px 0;

                    .img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
.login_image {
    width: 100%;
    min-width: 1366px;
    height: 10px;
    background-repeat: repeat;
    background-color: #515a6e;
    background-size: 100% auto;
    background-position: center center;
    margin-top: calc((100vh - 550px) / 2);
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.login_div {
    color: #000;
    background-color: #fff;
    border: 0pt;
    font-size: 14px;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 456px;
    height: 689px;
    position: fixed;
    right: 10%;
    top: 0px;
    bottom: 0px;
    margin: auto;
    border-radius: 5px;
    box-shadow: 2px 5px 10px #00000099;
    z-index: 200;
}
.sysitem {
    width: 300px;
    height: 200px;
    background: #eee;
    margin: 20px;
    float: left;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px #ddd;
    overflow: hidden;
    cursor: pointer;
}
.sysitem img {
    min-width: 100%;
    height: 150px;
    border-radius: 5px 5px 0 0;
    clear: both;
}
.sysitem p {
    line-height: 40px;
    font-size: 16px;
    text-align: center;
}
.layout-logo {
    float: left;
    line-height: 60px;
    height: 50px;
    margin: 5px;
}
.layout-nav {
    float: left;
    width: calc(100% - 400px);
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
}
.layout-right {
    float: right;
}

.leftmenubtn {
    position: fixed;
    top: 40%;
    left: 200px;
    z-index: 1;
    background: #ddd;
    width: 8px;
    line-height: 100px;
    color: #999;
    border: 1px solid #ddd;
    border-left: 0;
    border-radius: 0 3px 3px 0;
    overflow: hidden;
    cursor: pointer;
}
.leftmenubtn:hover {
    background: #fff;
    color: #ccc;
    border: 1px solid #ccc;
    border-left: 0;
}
.mscroll {
    float: left;
    width: 20px;
    color: #eee;
    background: #00000000;
    text-align: center;
    cursor: pointer;
    z-index: 19999;
    position: relative;
}
.mscroll:hover {
    background: #00000020;
}

.capcth-div .ivu-form-item-content {
    display: flex !important;
}
.capcth-div .captcha-btn {
    border-radius: 5px;
    padding: 0 20px;
    margin-left: 20px;
    height: 35px;
    line-height: 35px;
    background: #2db7f5;
    color: #fff;
    margin-top: 2px;
    cursor: pointer;
    user-select: none;
}
.capcth-div .none {
    background: none;
    border: 1px solid #ccc;
    color: #ccc;
}
</style>
<style lang="less" scoped>
.org-content {
    /deep/.v,
    .ivu-menu-vertical {
        width: 100% !important;
    }
    .right {
        margin-left: 10px;
        height: 100%;
        overflow: scroll;
        .org-item {
            cursor: pointer;
            user-select: none;
            margin: 5px 0;
        }
        .active {
            background: #2db7f5;
            color: #fff;
        }
    }
}
</style>
